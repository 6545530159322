* {
  box-sizing: border-box;
}

:root {
  --primary-colour: #80e3eb;
  --main-width: 60rem;
  --header-height: 7rem;
  font-size: 1.2rem;
  color: white;
}

body {
  background-color: var(--primary-colour);
  background: linear-gradient(
    90deg,
    rgba(0, 191, 207, 1) 0%,
    rgba(128, 227, 235, 1) 25%,
    rgba(142, 247, 255, 1) 50%,
    rgba(128, 227, 235, 1) 75%,
    rgba(0, 191, 207, 1) 100%
  );
  background-size: 300%;
  animation: bg-animation 15s infinite alternate ease-in-out;
  margin: 0 auto;
  letter-spacing: 0.05rem;
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

h1,
h2,
p {
  text-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3);
  font-size: 20pt;
}

img {
  max-width: 95%;
}

main {
  max-width: var(--main-width);
  padding: 0.5rem;
  padding-top: 6rem;
  margin: 0 auto;
  text-align: center;
}

header {
  z-index: 5;
  display: flex;
  flex-shrink: 1;
  width: 100%;
  height: var(--header-height);
  position: fixed;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  transition: 0.4s;
}

header::before {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: 0.4s;
  z-index: -1;
  content: "";
}

header.shrunk::before {
  opacity: 1;
  content: "";
}

header.shrunk {
  height: 3rem;
  transition: all 0.4s;
}

header img {
  width: var(--header-height);
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.8));
  transition: 0.4s;
  margin-left: -1rem;
}

header.shrunk img {
  width: 3rem;
  margin-left: -0.5rem;
  transition: 0.4s;
}

header h1 {
  color: white;
  font-size: 1.8rem;
  transition: 0.4s;
  margin-left: -1rem;
}

header.shrunk h1 {
  font-size: 1.2rem;
  color: var(--primary-colour);
  text-shadow: none;
  margin-left: 0;
  transition: 0.4s;
}

.headContainer {
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  flex-shrink: 1;
  align-items: center;
  margin: 0 auto;
}

.mainContainer {
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.heroCTA {
  margin: 0 auto;
  height: 100vh;
  margin-bottom: 10rem;
}

.heroCTA h1 {
  font-size: 1.3rem;
}

.intro {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  color: #444;
  padding: 1rem 1.5rem;
  text-align: left;
}

input {
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.3);
  margin-block: 0.5rem;
}

button {
  background-color: white;
  padding: 1rem;
  border: 0;
  border-radius: 0.4rem;
  margin-top: 0.2rem;
  box-shadow: 0 3px 0.3rem rgba(0, 0, 0, 0.5);
  width: 100%;
}

hr {
  margin-top: calc(var(--header-height) * 2.6);
  border: var(--primary-colour) dotted 5px;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.transition-div {
  vertical-align: top;
  transition: all 1.25s ease-in-out;
}

.transition-0 {
  transform: translateX(-1rem);
  opacity: 0;
  filter: blur(4px);
}
.transition-1 {
  transform: translateX(0);
  opacity: 1;
  filter: blue(0);
}

.transition-div {
  transition-delay: 0s;
}

.cta {
  background-color: white;
  padding: 2rem;
  padding-top: 1rem;
  border-radius: 1rem;
  color: var(--primary-colour);
  text-align: left;
}

.cta > h2 {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

button {
  background-color: var(--primary-colour);
}

.updateForm {
  margin: 0 auto;
  max-width: 40rem;
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
}

.thanks-chapter {
  font-size: 22pt;
  text-align: left;
  background-color: #fff;
  color: #000;
  max-width: 100%;
  margin: 0 auto;
  padding-inline: 1rem;
  padding-block: 0.1rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

.screenshots {
  margin-top: 5rem;
}

.thanks {
  border: 2px solid rgba(0, 191, 207, 1);
  border-radius: 2rem;
  padding: 0.4rem;
  background-color: rgba(0, 191, 207, 0.2);
  margin-top: 2rem;
}

@media (width > 40em) {
  .demo {
    display: inline-block;
    max-width: 30%;
  }
  .demo:nth-child(2) {
    transition-delay: 0s;
  }
  .demo:nth-child(3) {
    transition-delay: 0.7s;
  }
  .demo:nth-child(4) {
    transition-delay: 1.4s;
  }

  #hero {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: start;
    gap: 2rem;
    /* padding: 1rem; */
  }

  .heroCTA {
    border-bottom: 0rem;
  }

  .cta {
    max-width: 24rem;
  }

  hr {
    margin-top: -10rem;
  }
}
